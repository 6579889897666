class Acd {
  constructor() {
    //サイドバー
    $('.js-list__item').on('click', (e) => {
      e.stopPropagation();
      let target = $(e.currentTarget);
      let attr = $(e.target).attr('href');
      if (typeof attr === 'undefined' || attr === false) {
        let slide = target.find('>.js-side-inner-list');
        slide.stop(true, true).slideToggle(100, () => {
          if (slide.is(':hidden')) {
            target.removeClass('is-active').removeClass('is-current')
            slide.find('.js-side-inner-list').slideUp();
          } else {
            // $('.js-list__item').removeClass('is-active').removeClass('is-current');
            target.addClass('is-active').parents('.js-list__item').addClass('is-active');
          }
        });
      }
    })
    $('.js-list__item.is-current').trigger('click');

    //テーブル
    $('.js-ac-switch').on('click', (e) => {
      let target = $(e.currentTarget);
      if (target.hasClass('is-active')) {
        target.removeClass('is-active').siblings().stop(true, true).slideUp(200);;
      } else {
        target.addClass('is-active').siblings().stop(true, true).slideDown(200);
      }
    })
  }
}

export default Acd;