  import Acd from './Acd';
  import Menu from './Menu';
  import Scroll from './Scroll';
  import Tab from './Tab';

  $(function() {
    new Menu();
    new Scroll();
    new Acd();
    new Tab();
    if ($.fn['slick'] != null) {

      $('.js-slick').slick({
        infinite: true,
        autoplay: true,
        accessibility: true,
        autoplaySpeed: 3000,
        speed: 1000,
        pauseOnHover: true,
        dots: true,
        arrows: false,
        lazyLoad: 'ondemand',
        fade: true,
      });

      $('.js-slick02').slick({
        infinite: true,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        accessibility: true,
        speed: 1000,
        pauseOnHover: true,
        dots: false,
        arrows: false,
        lazyLoad: 'ondemand',
        responsive: [{
          breakpoint: 768, //767px以下のサイズに適用
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: true,
          }
        }]
      });
    }

    //文字調整
    $('.js-txt-large').on('click', (e) => {
      $('html').addClass('txt-large');
      $(e.currentTarget).addClass('is-active').siblings().removeClass('is-active');

    });
    $('.js-txt-normal').on('click', (e) => {
      $('html').removeClass('txt-large');
      $(e.currentTarget).addClass('is-active').siblings().removeClass('is-active');
    });
  });