class Tab {
  constructor() {
    $('.js-tab-select').on('click', (e) => {
      let clickitem = $(e.currentTarget);
      clickitem.closest('li').addClass('is-active').siblings().removeClass('is-active')
      let target = clickitem.attr('href');
      $(target).show(0).siblings().hide(0);
      return false;
    })

    $('.is-active .js-tab-select').trigger('click');
  }
}

export default Tab;